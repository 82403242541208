import { Table } from 'react-bootstrap';
import $ from 'jquery';
import { useEffect, useState } from "react";


function addNew(){

    let exchangeSelected=$("#exchange").val()
    let selectedExchangeCoins=$(`#${exchangeSelected}-1`).text()

    let coinDetails=`${$("#exchange").val()}=${$("#coin_name").val()},${selectedExchangeCoins}`

    $.get(`https://7mttjllrh3.execute-api.us-east-1.amazonaws.com/default/exchange-listing-reactApp?save=${coinDetails}&user=vaisakh`, (data, status) => {
        if (status == "success") {
            alert(JSON.parse(data))
        }
        else {
            alert("error occured")
        }
    })
}


function NewListings() {

    const [fetchedData, setFetchedData] = useState([]);

    useEffect(async () => {
        async function getCoins() {
            return new Promise((resolve, reject) => {
                $.get("https://7mttjllrh3.execute-api.us-east-1.amazonaws.com/default/exchange-listing-reactApp?get=true", (data, status) => {
                    if (status == "success") {
                        resolve(JSON.parse(data))
                    }
                    else {
                        reject("error occured")
                    }
                })
            })

        }
        var details = await getCoins()
        setFetchedData(details)


    }, []);


    const divStyle = {
        backgroundColor: "grey",
    }


    let coins = ["HBAR", "KDA"]

    let coinOptions = []
    let exchanges=Object.keys(fetchedData)
    exchanges.forEach((item, index) => {
        coinOptions.push(
            <>
                <tr>
                    <td>{index + 1}</td>
                    <td>{item}</td>
                    <td id={item+"-1"}>{fetchedData[item]}</td>
                </tr>
            </>
        )
    })

    return <div className='main-page' style={divStyle}>
        <div style={divStyle}>
            <div style={{ 'marginLeft': '25%', "marginTop": "5%" }}>


                <div className="row mt-4">
                    <div className="col-md-3">
                        Coin:
                    </div>
                    <div className="col-md-3">
                        <Table striped bordered hover size='sm'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Exchange</th>
                                    <th>Coin Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {coinOptions}
                            </tbody>
                        </Table>
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-3">
                        Exchange:
                    </div>
                    <div className="col-md-3">
                        <select className="form-control" style={{ "width": "50%" }} id="exchange">
                            <option value="coinbase">Coinbase</option>
                            <option>Binance</option>
                        </select>
                    </div>

                </div>
                <div className="row mt-4">
                    <div className="col-md-3">
                        Add New Coin:
                    </div>
                    <div className="col-md-3">
                        <input className='form-control' id='coin_name'/>
                    </div>
                    <div className="col-md-3">
                        <button className='btn btn-success' onClick={addNew}>Add New</button>
                    </div>
                </div>
            </div>

        </div>
    </div>;
}

export default NewListings