import { Outlet, Link, NavLink } from "react-router-dom";
import { Navbar, Nav, NavItem, NavDropdown, MenuItem, Container } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";


function NavBar() {
  let navigate = useNavigate()

  let user = localStorage.getItem("user")

  function Logout() {
    localStorage.removeItem("user")
    window.location.href = '#/logout'
  }


  if (!user || user == null) {
    window.location.href = "#/login"
  }


  return <>
    <Navbar bg="dark" expand="lg" fixed="top" collapseOnSelect>
      <Container>
        {/* <Navbar.Brand as={Link} to='/' style={{color:"white"}}>React-Bootstrap</Navbar.Brand> */}
        <Navbar.Toggle area-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <Nav.Link style={{ color: "white" }} as={NavLink} to='/'>Alerts</Nav.Link>
            <Nav.Link as={NavLink} to='/newlistings' style={{ color: "white" }}>New Listings</Nav.Link>
            <Nav.Link as={NavLink} to='/socials' style={{ color: "white" }}>Socials</Nav.Link>

            {/* <Nav.Item className="ml-auto">
              <Nav.Link style={{ color: "white" }}> {localStorage.getItem("user")}</Nav.Link>
            </Nav.Item>
            <Nav.Link className="me-auto">
              <button className='btn btn-danger' onClick={Logout} >Logout</button>
            </Nav.Link> */}


            <NavDropdown title={<span style={{ color: "white" }}>Actions</span>} id="basic-nav-dropdown" >
              <NavDropdown.Item >Logged In as: <button className='btn btn-info'>{localStorage.getItem("user")}</button></NavDropdown.Item>
              {/* <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item> */}
              <NavDropdown.Divider />
              <NavDropdown.Item ><button className='btn btn-danger' onClick={Logout} >Logout</button></NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>

    </Navbar>
    <Outlet />
  </>
}

export default NavBar