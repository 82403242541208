import './App.css';
import { useState } from "react";
import Login from './components/login';
import PriceSet from './components/price-set';
import NavBar from "./components/navbar"
import Layout from "./components/Layout"
import Socials from "./components/Socials"
import { HashRouter, Routes, Route } from "react-router-dom";
import NewListings from "./components/Listings"

function App() {

  const [userName, setUserName] = useState(localStorage.getItem("user"));

  // if (!userName) {
  //   return <Login setUserName={setUserName} />
  // }
  // return <div>
  //   <NavBar/>
  //   <PriceSet />
  //   </div>

return (
  <HashRouter>
    <Routes>
      <Route exact path="/" element={<NavBar/> }>
        <Route index element={<PriceSet />} />
        <Route path="price-volume-alerts" element={<PriceSet />} />
        <Route path="newlistings" element={<NewListings />} />
        <Route path="socials" element={<Socials />} />
        {/* <Route path="logout" element={<Login />} />
        <Route path="login" element={<Login />} /> */}
      </Route>
      <Route exact path="/login" element={<Login/>}>
      </Route>
      <Route exact path="/logout" element={<Login />}>
      </Route>
    </Routes>
  </HashRouter>
);
}



export default App;
