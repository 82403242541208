import PropTypes from 'prop-types';
import { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
var Loader = require('react-loader');

function Login() {
    // function Login({ setUserName }) {

    let navigate = useNavigate(); 

    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [isLoaded, setLoadStatus] = useState(true)

    async function handleSubmit() {
        // localStorage.setItem("user", userName)
        // localStorage.setItem("password", password)
        setLoadStatus(false)
        let loginURL=`https://7nlzqu0l3d.execute-api.us-east-1.amazonaws.com/default/LoginUser?get=1&user=${userName}&password=${password}`

        $.get(loginURL, (data, status) => {

            let userDetails=data.body.userDetails
            let token=data.body.token
            localStorage.setItem("token",token)
            let user=Object.keys(userDetails)[0]

            if (user==userName) {
                localStorage.setItem("user", userName)
                setLoadStatus(true)

                navigate("/price-volume-alerts")
            }
            else {
                alert("Wrong Username or Password")
            }
        })

        // setUserName(1)
        

    }

    return <div>
        <label style={{"position":"absolute","right":"7%","top":"1%"}}>New here?
        
            
        </label>
        <button type="button"  className="btn btn-success login-button" onClick={handleSubmit}>Sign Up</button>
        
        <div style={{ "marginLeft": "30%", "marginRight": "30%", "marginTop": "15%" }}>
        
            <form method="" action="">
                <div className='row'>
                    <div className='col-md-9'>
                        <input type="text" placeholder="Username" name="search-str" id="search-str" className="form-control login-input" onChange={e => setUserName(e.target.value)} />
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-9'>
                    <Loader loaded={isLoaded}></Loader>
                        <input type="password" placeholder="Password" name="search-str" id="search-str" className="form-control login-input" onChange={e => setPassword(e.target.value)}/>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-4' style={{"marginLeft":"8%"}}>
                        <button type="button"  className="btn btn-primary login-button" onClick={handleSubmit}>Login</button>
                       
                    </div>
                </div>
                
                
               
                {/* <Link to="/price-volume-alerts" type="button" className="btn btn-primary login-button">Home</Link> */}
            </form>
        </div>
    </div>
}


export default Login;

// Login.propTypes = {
//     setUserName: PropTypes.func.isRequired
// };