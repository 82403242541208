import { useState } from "react";

function CustomInput(props) {
    const [value, setValue] = useState(props.value)
    // const [id,setId]=useState(props.id)
    // alert(props.value)
    // setValue(props.value)
    const onChange = (event) => {
        setValue(event.target.value);
    };

    return <div>

        <input type="number" placeholder="alert if price goes below" id={props.id} className='form-control' value={value} onChange={onChange} />


    </div>
}

export default CustomInput;