import $ from 'jquery';
import { useEffect, useState } from "react";
import CustomInput from "./controlledInput"
import Navbar from "./navbar"
var Loader = require('react-loader');

var axios = require('axios');
let coinCount = 0






function saveDetails() {
    var details = ""
    var coinNumber = document.getElementsByTagName('input').length

    for (var i = 0; i < coinNumber; i = i + 5) {
        let val = $(`#${i}`).val()
        let filter = $(`input[name="${val}"]:checked`).val()
        details = details + $(`#${i}`).val() + "=" + $(`#${i + 2}`).val() + "=" + $(`#${i + 3}`).val() + "=" + $(`#${i + 4}`).val() + ","
        // + "="+filter+","
    }
    console.log(details)
    if (details.endsWith(",")) {
        details = details.slice(0, -1)
    }

    console.log(details, "Save details")


    $.get(`https://rvge5zirbc.execute-api.us-east-1.amazonaws.com/default/save-crypto-details-reactApp?save=${details}&user=vaisakh`, (data, status) => {
        if (status == "success") {
            alert("Data saved successfully")
            window.location.reload();
        }
        else {
            alert("error occured")
        }
    })
}


function Logout() {
    localStorage.removeItem("user")
    window.location.href = '/logout'
}


function PriceSet() {

    const divStyle = {
        backgroundColor: "grey",
        // backgroundSize: 'cover',
        // marginLeft:"15%",
        // backgroundRepeat: 'no-repeat'
        // backgroundRepeat: 'repeat-y'
    }

    const [fetchedData, setFetchedData] = useState([]);
    const [counter, setCounter] = useState(0);
    const [loggedInUser, setLoggedInUser] = useState('');
    const [currentPrice, setCurrentPrice] = useState({})
    const [isLoaded, setLoadStatus] = useState(false)

    function addNew() {
        var coinNumber = document.getElementsByTagName('input').length / 5
        coinCount++

        $("#addNew").prop("disabled", true)
        setCounter(coinCount)
    }



    useEffect(async () => {
        async function getCoins() {
            console.log(localStorage.getItem("user"), "user")
            // loggedInUser=localStorage.getItem("user")
            setLoggedInUser(localStorage.getItem("user"))
            return new Promise((resolve, reject) => {
                $.get("https://rvge5zirbc.execute-api.us-east-1.amazonaws.com/default/save-crypto-details-reactApp?get=1", (data, status) => {
                    if (status == "success") {

                        resolve(JSON.parse(data))
                    }
                    else {
                        reject("error occured")
                    }
                })
            })

        }
        var details = await getCoins()
        setFetchedData(details)



        let coins = Object.keys(details)
        let currentPriceDict = {}
        async function fetchCurrentPrice(coins) {

            var config = {
                method: 'get',
                url: `https://7b05zf9fq8.execute-api.us-east-1.amazonaws.com/default/get-current-price?get=1`,
                headers: {
                }
            };
            return new Promise((resolve, reject) => {
                axios(config)
                    .then(function (response) {
                        console.log(response.data, "////////")
                        setLoadStatus(true)
                        // console.log(JSON.stringify(response.data));
                        // response.data = JSON.parse(response.data)
                        resolve(response.data)

                    })
                    .catch(function (error) {
                        console.log(error, "???????????????");
                        coins.forEach((coin) => {
                            currentPriceDict[coin] = 1

                        })
                        resolve(currentPriceDict)
                    });
            })

        }

        let response = await fetchCurrentPrice(coins)
        setCurrentPrice(response)
        // setCounter(coins.length)


        // const intervalId = setInterval(async () => {
        //     let response = await fetchCurrentPrice(coins)
        //     setCurrentPrice(response)
        // }, 50000) // in milliseconds
        // return () => clearInterval(intervalId)





    }, []);


    // setInterval(()=>{
    //     window.location.href="/"
    // },20000)


    function deleteCoin(e) {


        if (!window.confirm("Press a button!\nEither OK or Cancel.")){
            return
        }
        console.log(e.target.id)

        // let data = JSON.stringify(
        //    {"coin":e.target.id} 
        // );

        var config = {
            method: 'get',
            url: `https://xdukjn8ev5.execute-api.us-east-1.amazonaws.com/default/deleteCoin-reactapp?coin=${e.target.id}`,
            headers: {
            }
        };
        axios(config)
            .then(function (response) {
                alert(`${e.target.id} is deleted`)
                window.location.reload();
            })
            .catch(function (error) {
                console.log(`failed to delete ${e.target.id}`);
            });

    }




    let menuItems = []
    let coins = Object.keys(fetchedData)
    console.log(coins.join(","), "///")
    // fetchCurrentPrice(coins,setCurrentPrice)

    let k = 0
    for (var i = 0; i < coins.length; i++) {
        console.log(currentPrice, "///////")
        let minValue = fetchedData[coins[i]].split(",")[0]
        let maxValue = fetchedData[coins[i]].split(",")[1]
        let volumeThreshold = fetchedData[coins[i]].split(",")[2]
        menuItems.push(<label style={{ "marginLeft": "2%", 'color': 'white' }}>Coin: <input type="text" placeholder='coin' value={coins[i]} id={k} className='form-control' /></label>)
        menuItems.push(<label style={{ "marginLeft": "2%", 'color': 'white' }}>Current Price: <input type="number" placeholder='current price' value={currentPrice[coins[i]]} id={k + 1} className='form-control' disabled /></label>)
        menuItems.push(<label style={{ "marginLeft": "2%", 'color': 'white' }}>Lower End: <CustomInput id={k + 2} value={minValue} text="Lower End: " /></label>)
        menuItems.push(<label style={{ "marginLeft": "2%", 'color': 'white' }}>Upper End: <CustomInput id={k + 3} value={maxValue} text="Upper End: " /></label>)
        menuItems.push(<label style={{ "marginLeft": "2%", 'color': 'white' }}>% volume change (1 hr): <CustomInput id={k + 4} value={volumeThreshold} text="% volume change (1 hr): " /></label>)
        menuItems.push(<button style={{ "marginLeft": "2%", 'color': 'white' }} className="btn btn-danger" id={coins[i]} onClick={deleteCoin}>Delete</button>)
        // menuItems.push(<label style={{ "marginLeft": "2%", 'color': 'white' }}>Lower End: <input type="number" placeholder="alert if price goes below" id={k + 2} className='form-control' value={minValue} /></label>)
        // menuItems.push(<label style={{ "marginLeft": "2%", 'color': 'white' }}>Upper End: <input type="number" placeholder="alert if price goes above" value={maxValue} id={k + 3} className='form-control' /></label>)
        menuItems.push(<br />)
        menuItems.push(<br />)


        k = k + 5
    }


    for (var i = 0; i < counter; i = i + 5) {

        console.log(i, ">>>>>>>>")
        menuItems.push(<label style={{ "marginLeft": "2%", 'color': 'white' }}>Coin: <input type="text" placeholder='coin' className='form-control' id={k} /></label>)
        menuItems.push(<label style={{ "marginLeft": "2%", 'color': 'white' }}>Current Price: <input id={k + 1} type="number" placeholder='current price' className='form-control' disabled /></label>)
        menuItems.push(<label style={{ "marginLeft": "2%", 'color': 'white' }}>Lower End: <input id={k + 2} type="number" placeholder="alert if price goes below" className='form-control' /></label>)
        menuItems.push(<label style={{ "marginLeft": "2%", 'color': 'white' }}>Upper End: <input id={k + 3} type="number" placeholder="alert if price goes above" className='form-control' /></label>)
        menuItems.push(<label style={{ "marginLeft": "2%", 'color': 'white' }}>% volume change (1 hr): <input id={k + 4} type="number" placeholder="1 hr % change in volume traded" className='form-control' /></label>)
        menuItems.push(<button style={{ "marginLeft": "2%", 'color': 'white' }} className="btn btn-danger" id={coins[i]} onClick={deleteCoin}>Delete</button>)
        menuItems.push(<br />)
        menuItems.push(<br />)
    }

    // localStorage.setItem("numberOfCoins", inputLength / 5)


    return <div className='main-page' style={divStyle}>

        {/* <button style={{ "float": "right", "marginLeft": "1%" }} className='btn btn-danger' onClick={Logout}>Logout</button>
        <label style={{ "float": "right" }}>LoggedIn as: <button className='btn btn-info'>{loggedInUser}</button></label> */}
        <div style={divStyle}>
            <div style={{ 'marginLeft': '12%' }}><br />
                <Loader loaded={isLoaded}></Loader>
                <button className='btn btn-success' onClick={addNew} id="addNew" style={{ "marginLeft": "2%" }}>Add New</button>
                <br /><br />{menuItems}
                <button className='btn btn-primary' onClick={saveDetails} style={{ "marginLeft": "2%" }}>Save</button>
            </div>
        </div>
    </div>;


}

export default PriceSet